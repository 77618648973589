(function($) {
	'use strict';
	
	// Burger toggle
	const burger = document.getElementById('burger');

	if (burger) {
		burger.addEventListener(Utils.clickEvent, function() { 
			document.body.classList.toggle('menu-open') 
		});
	}

    const $body = $('body');
    
    $('.mod_search.searchbar').each(function() {
        let $module = $(this);
		let $input = $module.find('input[type="search"]');

		$input.siblings().filter('label').each(function() {
			$input.attr('placeholder', $(this).text());
		});

		$module.find('button.submit').click(function(e) {
			if (!$body.hasClass('show-search')) {
				e.preventDefault();
				$body.addClass('show-search');
				setTimeout(function() {
					$input.focus();
				}, 200)
				return false;
			}

			if ($input.val() == '') {
				e.preventDefault();
				$body.removeClass('show-search');
				return false;
			}
		});

		$module.find('form').submit(function(e) {
			if ($input.val() == '') {
				e.preventDefault();
				$body.removeClass('show-search');
				return false;
			}
		});

		$(document).keyup(function(e) {
			if (e.keyCode === 27) {
				$body.removeClass('show-search');
				setTimeout(function() {
					$input.val('');
				}, 200);
			}
		});
	});

	// Submenu toggle
	$('nav.subnav .page-title').on(Utils.clickEvent, function () {
		$(this).closest('nav.subnav').toggleClass('show');
	});

	// Check for has scrolled
	const checkScrolled = function() {
		const scrollPos = window.scrollY || document.body.scrollTop || document.documentElement.scrollTop;
		if (scrollPos > 0) {
			document.body.classList.add('scrolled');
		} else {
			document.body.classList.remove('scrolled');
		}
		window.requestAnimationFrame(checkScrolled);
	};
	window.requestAnimationFrame(checkScrolled);
})(jQuery);
